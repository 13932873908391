export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [2,4];

export const dictionary = {
		"/(app)": [5,[2],[3]],
		"/(app)/auth": [~7,[2],[3]],
		"/(app)/concursos": [8,[2],[3]],
		"/(app)/concursos/[id]": [9,[2],[3]],
		"/(protected)/fortress": [16,[4]],
		"/(protected)/fortress/registrations": [17,[4]],
		"/(app)/politicas/politica-de-privacidad": [10,[2],[3]],
		"/(app)/politicas/terminos": [11,[2],[3]],
		"/(app)/registrarse": [12,[2],[3]],
		"/(app)/temporadas": [13,[2],[3]],
		"/(app)/temporadas/[id]": [14,[2],[3]],
		"/(app)/votacion/[id]": [15,[2],[3]],
		"/(app)/[...fallback]": [6,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';